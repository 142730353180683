import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: 'Login' },
        component: () => import('../views/account/login.vue')
    },
    {
        path: '/',
        name: 'dashboard',
        meta: {
            title: 'Dashboard',
            authRequired: true,
        },
        component: () => import('../views/dashboard/index.vue'),
    },
    {
        path: '/clients',
        name: 'clients',
        meta: {
            title: 'Clients',
            authRequired: true,
        },
        component: () => import('../views/clients/index.vue'),
    },
    {
        path: '/packing-slips',
        name: 'packing-slips',
        meta: {
            title: 'Packing Slips',
            authRequired: true,
        },
        component: () => import('../views/packing-slips/index.vue'),
    },
    {
        path: '/lost-outbound',
        name: 'lost-outbound',
        meta: {
            title: 'Lost Outbound',
            authRequired: true,
        },
        component: () => import('../views/lost-outbound/index.vue'),
    },
    {
        path: '/disposal',
        name: 'disposal',
        meta: {
            title: 'Disposal',
            authRequired: true,
        },
        component: () => import('../views/disposal/index.vue'),
    },
    {
        path: '/whsetransfer',
        name: 'whsetransfer',
        meta: {
            title: 'Warehouse Transfer',
            authRequired: true,
        },
        component: () => import('../views/whsetransfer/index.vue'),
    },
    // {
    //     path: '/pages/starter',
    //     meta: { authRequired: true, title: 'Starter Page' },
    //     component: () => import('../views/utility/starter.vue')
    // },
    // {
    //     path: '/pages/maintenance',
    //     meta: { authRequired: true, title: 'Maintenance' },
    //     component: () => import('../views/utility/maintenance.vue')
    // },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
    // set title name
    if (routeTo.meta.title != undefined) {
        document.title = routeTo.meta.title + " | Legacy Seller";
    }

    const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
    if (!authRequired) return next();

    if (localStorage.getItem('user')) {
        next();
    } else {
        next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
    }

});

export default router;
